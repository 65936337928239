<template>
  <div v-if="clip">
    <vs-dialog
      v-model="isactive"
      :close="$emit('update:active', isactive)"
      prevent-close
    >
      <template #header>
        <div class="center">
          <h2>Push</h2>
          <div>{{ clip.name }}</div>
        </div>
      </template>

      <div class="push-form">
        <vs-alert danger relief class="push-alert" v-if="clip.pushed">
          <template #title> Take bereits gepushed! </template>
          <div class="text-left">
            <div
              v-for="(push, i) in clip.pushed"
              :key="i"
              class="push-alert-stream"
            >
              <span class="clip-upload-icon material-icons-outlined"
                >cloud_upload</span
              ><span
                >{{ push.stream }} ({{
                  clipdate(clip.pushed[i].timestamp)
                }})</span
              >
            </div>
          </div> </vs-alert
        ><br />

        <b>Bett</b>
        <vs-radio v-model="bett" val="0" danger> Kein </vs-radio>
        <vs-radio v-model="bett" val="1" danger> Standard </vs-radio>
        <vs-radio v-model="bett" val="2" danger> Alternativ </vs-radio>
        <vs-radio v-model="bett" val="3" danger> Langsam </vs-radio>

        <div class="m-top-30">
          <b>Stream</b>
          <vs-select v-model="stream" multiple filter danger class="m-top-15">
            <vs-option
              :label="strm.name"
              :value="strm.id"
              v-for="strm in streamlist"
              :key="strm.id"
            >
              {{ strm.name }}
            </vs-option>
          </vs-select>
        </div>
      </div>

      <template #footer>
        <div class="footer-dialog">
          <vs-button
            block
            :disabled="!stream.length || !streams.length || !user.auth"
            danger
            class="m-top-15"
            @click="isactiveftp = true"
          >
            Upload
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog
      v-model="isactiveftp"
      :loading="ftploading"
      :prevent-close="ftploading"
      :not-close="ftploading"
      class="ftp-modal"
    >
      <template #header>
        <div class="center">
          <h2>Bestätigen</h2>
        </div>
      </template>
      <div class="infotext">
        Möchtest du diesen Take jetzt auf
        {{ stream.length === 1 ? "den Stream" : "die Streams" }}
        <b>{{
          streams
            .filter((x) => stream.includes(x.id))
            .map((x) => x.name)
            .join(", ")
        }}</b>
        pushen? <br />
        Du kannst dies danach nicht mehr rückgängig machen!
      </div>
      <template #footer>
        <div class="ftp-footer">
          <vs-button danger @click="ftpupload()"> Upload </vs-button>
          <vs-button transparent @click="isactiveftp = false">
            Abbrechen
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog v-model="isactiveerror" class="error-modal">
      <template #header>
        <div class="center">
          <h2>{{ error.header }}</h2>
        </div>
      </template>
      <div class="infotext">{{ error.text }}</div>
      <template #footer>
        <div class="ftp-footer">
          <vs-button danger @click="isactiveerror = false">
            Schließen
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<style scoped lang="scss">
.center {
  text-align: center;
}
.push-form {
  .vs-radio-content {
    margin: 8px 0;
    justify-content: flex-start;
  }
  .vs-select-content {
    max-width: 100%;
  }
  .push-alert {
    padding: 0 5px;
    .push-alert-stream {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
    }
  }
  .clip-upload-icon {
    vertical-align: middle;
    margin-right: 5px;
  }
}
.ftp-modal,
.error-modal {
  z-index: var(--vs-zindex-1);
}
.ftp-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>

<script>
import * as dayjs from "dayjs";

import { mapState } from "pinia";
import { mapActions } from "pinia";
import { useClipsStore } from "@/stores/clips";
import { useStreamsStore } from "@/stores/streams";
import { useUserStore } from "@/stores/user";

import { authOptions } from "../../store";
import axios from "axios";

export default {
  name: "Upload",
  props: ["clip", "active"],
  data: () => ({
    isactive: false,
    isactiveftp: false,
    ftploading: false,
    isactiveerror: false,
    bett: "0",
    stream: [],
    error: {
      success: false,
      header: "",
      text: "",
    },
  }),
  computed: {
    ...mapState(useStreamsStore, ["streams"]),
    ...mapState(useUserStore, ["user"]),
    streamlist() {
      if (this.user.auth) {
        if (this.user.auth.streams && this.user.auth.streams[0] == "all") {
          return this.streams;
        } else {
          return this.streams.filter((x) =>
            this.user.auth.streams.includes(x.id)
          );
        }
      } else {
        return [];
      }
    },
  },
  mounted() {
    if (this.user.auth) {
      this.stream = [
        this.user.auth.streams[0] == "all" ? "club" : this.user.auth.streams[0],
      ];
    }
  },
  methods: {
    ...mapActions(useClipsStore, ["saveClip"]),
    async dataURLtoFile(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];

      const res = await fetch(dataurl);
      const buf = await res.arrayBuffer();
      return new File([buf], "clip", { type: mime });
    },
    getMimeTypeExtension(data) {
      let mime = data.split(",")[0].match(/:(.*?);/)[1];
      let extension = "webm";
      switch (mime) {
        case "audio/webm":
          extension = "webm";
          break;
        case "audio/mpeg":
          extension = "mp3";
          break;
        case "audio/mp3":
          extension = "mp3";
          break;
        case "audio/aac":
          extension = "aac";
          break;
        case "audio/mp4":
          extension = "mp4";
          break;
        case "audio/x-m4a":
          extension = "m4a";
          break;
        case "audio/opus":
          extension = "opus";
          break;
        case "audio/ogg":
          extension = "ogg";
          break;
        case "audio/wav":
          extension = "wav";
          break;
        case "audio/3gpp":
          extension = "3gp";
          break;
        case "audio/3gpp2":
          extension = "3g2";
          break;
        case "audio/x-acc":
          extension = "aac";
          break;
        case "audio/ac3":
          extension = "ac3";
          break;
        case "audio/x-aiff":
          extension = "aif";
          break;
        case "audio/aiff":
          extension = "aif";
          break;
        case "audio/x-au":
          extension = "au";
          break;
        case "audio/x-flac":
          extension = "flac";
          break;
        case "audio/mpg":
          extension = "mp3";
          break;
        case "audio/mpeg3":
          extension = "mp3";
          break;
        default:
          extension = "webm";
          break;
      }
      return extension;
    },
    updateClip() {
      let editClip = this.clip;
      let toPush = {
        stream: this.stream
          .map((x) => this.streamlist.find((y) => y.id == x).name)
          .join(", "),
        timestamp: dayjs().format("YYYY-MM-DD-HH-mm-ss"),
      };
      if (editClip.pushed) {
        editClip.pushed.push(toPush);
      } else {
        editClip.pushed = [toPush];
      }
      this.saveClip(editClip).then(() => {
        // update clip list
      });
    },
    async ftpupload() {
      this.ftploading = true;
      let params = {
        stream: this.stream.join(","),
        bett: this.bett,
        user: this.user.rm_user_id,
        debug: {
          nickname: this.user.nickname,
          useragent: navigator.userAgent,
          codec: this.clip.audio.split("base64,")[0],
        },
      };
      //console.log(this.clip);
      let base64File = this.clip.audio;
      /*
      if (base64File.indexOf("data:") >= 0) {
        base64File = base64File.substr(5);
      }
      */

      if (!base64File.length) {
        this.error.success = false;
        this.error.header = "Fehler";
        this.error.text = "Dein Clip ist leer!";
        this.isactiveftp = false;
        this.isactiveerror = true;
        this.ftploading = false;
        return;
      }

      const bFile = await this.dataURLtoFile(base64File);
      const data = new FormData();
      data.append(
        "clip",
        bFile,
        "clip." + this.getMimeTypeExtension(base64File)
      );

      axios
        .post(authOptions.proxy, data, {
          params: params,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(({ data }) => {
          if (data == "done") {
            //console.log(data);
            this.error.success = true;
            this.error.header = "Erfolg";
            this.error.text = "Dein Clip wurde erfolgreich hochgeladen!";

            this.updateClip();
          } else {
            this.error.success = false;
            this.error.header = "Fehler";
            this.error.text =
              "Dein Clip konnte nicht hochgeladen werden! " + data;
          }
          this.isactiveftp = false;
          this.isactiveerror = true;
          this.ftploading = false;
        })
        .catch((e) => {
          console.log(e);
          this.error.success = false;
          this.error.header = "Fehler";
          this.error.text =
            "Dein Clip konnte nicht hochgeladen werden! Überprüfe deine Internetverbindung";
          this.isactiveftp = false;
          this.isactiveerror = true;
          this.ftploading = false;
        });
    },
    clipdate(time) {
      let timestamp = dayjs(time, "YYYY-MM-DD-HH-mm-ss");
      return timestamp.format("D. MMMM YYYY - HH:mm:ss");
    },
  },
  watch: {
    active() {
      this.isactive = this.active;
    },
    "user.auth.streams": function() {
      if (this.user.auth) {
        this.stream = [
          this.user.auth.streams[0] == "all"
            ? "club"
            : this.user.auth.streams[0],
        ];
      }
    },
  },
};
</script>
