import { defineStore } from "pinia";
import { useUserStore } from "./user";

export const useSettingsStore = defineStore("settings", {
  // convert to a function
  state: () => ({
    setting: {
      playListOnlyAvailable:
        JSON.parse(localStorage.getItem("playListOnlyAvailable")) || false,
      lastPlayedSender: localStorage.getItem("lastPlayedSender") || "club",
    },
  }),
  getters: {
    settings(state) {
      return state.setting;
    },
  },
  actions: {
    getSettings() {
      if (!localStorage.getItem("lastPlayedSender")) {
        const userStore = useUserStore();
        this.setting.lastPlayedSender =
          userStore.auth.streams && userStore.auth.streams[0] != "all"
            ? userStore.auth.streams[0]
            : "club";
      }
      return this.setting;
    },
    togglePlayListOnlyAvailable(val) {
      this.setting.playListOnlyAvailable = val;
      localStorage.setItem("playListOnlyAvailable", val);
    },
    setLastPlayedSender(val) {
      this.setting.lastPlayedSender = val;
      localStorage.setItem("lastPlayedSender", val);
    },
  },
});
