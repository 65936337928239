import localforage from "localforage";

export const localForageService = localforage.createInstance({
  name: "myrm",
  version: 1.0,
  storeName: "myrm_clips",
});

localForageService
  .setDriver([
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    localforage.LOCALSTORAGE,
  ])
  .then(() => {
    // console.log("Storage init");
  })
  .catch((error) => {
    console.log("Storage failed", error);
  });

export default {
  localForageService,
};
