<template>
  <div class="record-page">
    <div class="record-wrapper">
      <div
        :class="[
          'record-bottom-bar',
          { 'full-width': inputDevices.length == 1 },
        ]"
      >
        <canvas class="visualizer" ref="visualizer"></canvas>
        <div class="record-actions">
          <vs-button
            active
            icon
            danger
            circle
            animation-type="scale"
            @click="
              mediaRecorder && mediaRecorder.state == 'recording'
                ? stop()
                : record()
            "
          >
            <span class="material-icons-outlined" v-if="recorderRecording"
              >stop</span
            >
            <span class="material-icons-outlined" v-else>mic_none</span>
            <template #animate>
              <span class="material-icons" v-if="recorderRecording">stop</span>
              <span class="material-icons" v-else>mic</span>
            </template>
          </vs-button>

          <div
            :class="
              'record-timer ' + (recordTime >= 170 ? 'record-danger' : '')
            "
          >
            <span class="record-timer-minutes">{{ timer(recordTime)[0] }}</span
            ><span class="record-timer-divider">:</span
            ><span class="record-timer-seconds">{{
              timer(recordTime)[1]
            }}</span>
          </div>
        </div>
      </div>
      <div class="device-bottom-bar" v-if="inputDevices.length != 1">
        <vs-button transparent icon shadow @click="inputDeviceModal = true">
          <span class="material-icons-outlined" v-if="inputDevices.length"
            >mic_external_on</span
          >
          <span class="material-icons-outlined" v-else>mic_external_off</span>
        </vs-button>
      </div>
    </div>
    <vs-row justify="center" align="center">
      <vs-col
        type="flex"
        justify="center"
        align="center"
        xs="12"
        lg="6"
        class="clip-item-wrapper"
      >
        <div v-if="!filteredClips.length">
          <vs-alert danger relief>
            <template #icon>
              <span class="material-icons-outlined">touch_app</span>
            </template>
            <template #title> Willkommen bei RM2GO! </template>
            <div class="text-left">
              Berühre unten den Button um einen Take zu starten und beende ihn
              wieder mit einem Klick!<br />
              Du kannst bis zu 3 Minuten aufnehmen.
            </div>
          </vs-alert>
        </div>
        <transition-group
          :name="transitiongroupname"
          mode="out-in"
          tag="div"
          :class="transitiongroupname"
        >
          <div v-for="clip in filteredClips" :key="clip.id" class="clip-item">
            <div>
              <audio
                :src="clip.audio"
                controls
                preload="auto"
                class="clip-audio"
              ></audio>
              <div class="clip-item-actions">
                <div class="clip-item-upload">
                  <vs-button icon success circle @click="uploadClip(clip)">
                    <span class="material-icons-outlined">cloud_upload</span>
                  </vs-button>
                </div>
                <div>
                  <div class="clip-infos">
                    <vs-tooltip>
                      <span v-if="clip.pushed"
                        ><span class="clip-upload-icon material-icons-outlined"
                          >cloud_upload</span
                        >{{
                          clipdate(
                            clip.pushed[clip.pushed.length - 1].timestamp
                          )[0]
                        }}</span
                      >
                      <template #tooltip>
                        <div v-for="(push, i) in clip.pushed" :key="i">
                          {{ push.stream }} ({{
                            clipdate(clip.pushed[i].timestamp)[1]
                          }})
                        </div>
                      </template>
                    </vs-tooltip>
                    <vs-tooltip>
                      <span class="timestamp"
                        ><span class="clip-info-icon material-icons-outlined"
                          >mic</span
                        >{{ clipdate(clip.id)[0] }}</span
                      >
                      <template #tooltip>
                        {{ clipdate(clip.id)[1] }}
                      </template> </vs-tooltip
                    ><span
                      ><span class="clip-info-icon material-icons-outlined"
                        >schedule</span
                      >{{ timer(clip.duration || 0, true) }}</span
                    >
                  </div>
                  <div class="clip-item-label">
                    <span>{{ clip.name }}</span
                    ><vs-button
                      icon
                      circle
                      transparent
                      color="#7f7f7f"
                      size="mini"
                      @click="editClip(clip)"
                    >
                      <span class="material-icons-outlined">edit</span>
                    </vs-button>
                  </div>
                </div>
                <div class="clip-item-delete">
                  <vs-button
                    icon
                    danger
                    circle
                    border
                    @click="deleteClipModal(clip)"
                  >
                    <span class="material-icons-outlined">delete</span>
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </vs-col>
    </vs-row>
    <Upload :clip="clipToUpload" :active.sync="uploadModal"></Upload>

    <vs-dialog width="300px" not-center v-model="clipRenameModal" prevent-close>
      <template #header>
        <h4 class="not-margin">Clip umbenennen</h4>
      </template>

      <div class="editclip">
        <vs-input v-model="editclip.name" placeholder="Name"></vs-input>
      </div>

      <template #footer>
        <div class="actions-footer">
          <vs-button @click="renameClip(editclip)" danger>
            Speichern
          </vs-button>
          <vs-button @click="clipRenameModal = false" transparent>
            Abbrechen
          </vs-button>
        </div>
      </template>
    </vs-dialog>

    <vs-dialog width="300px" not-center v-model="inputDeviceModal">
      <template #header>
        <h4 class="not-margin">Aufnahmegerät auswählen</h4>
      </template>

      <div class="editinputdevice">
        <div v-if="inputDevices.length">
          <div class="text-muted">
            Deine Auswahl gilt nur für diese Session.
          </div>
          <br />
          <vs-select v-model="selectedInputDevice" danger>
            <vs-option
              :label="device.label"
              :value="device.deviceId"
              v-for="device in inputDevices"
              :key="device.deviceId"
            >
              {{ device.label }}
            </vs-option>
          </vs-select>
        </div>
        <div v-else>Dein Gerät unterstützt diese Funktion leider nicht.</div>
      </div>

      <template #footer>
        <div class="actions-footer">
          <vs-button @click="inputDeviceModal = false" transparent>
            Schließen
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<style lang="scss">
.record-page {
  padding-bottom: 100px;
}
.text-left {
  text-align: left;
}
.text-muted {
  opacity: 0.7;
}
.clip-item-wrapper {
  flex-direction: column;
  overflow: hidden;
}
.record-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 80px;
  width: 100%;
  z-index: 98;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, var(--vs-shadow-opacity)),
    transparent
  );
  backdrop-filter: blur(3px);
}
.record-bottom-bar {
  position: fixed;
  width: calc(100% - 100px);
  bottom: 15px;
  left: 0;
  margin: 0 15px;
  background: RGB(var(--vs-background));
  border-radius: 15px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
  z-index: 99;
  overflow: hidden;
}
.record-bottom-bar.full-width {
  width: calc(100% - 30px);
}
.record-actions {
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    border-radius: 40px;
    span {
      font-size: 40px;
    }
  }
}
.record-timer {
  margin-left: 15px;
  backdrop-filter: blur(3px);
  color: RGB(var(--vs-text));
  width: 100px;
  text-align: center;

  .record-timer-minutes,
  .record-timer-divider,
  .record-timer-seconds {
    font-size: 30px;
    font-weight: 300;
  }
  .record-timer-minutes {
  }
  .record-timer-divider {
    font-weight: 400;
  }
  .record-timer-seconds {
  }
}
.record-danger {
  color: rgba(var(--vs-danger), 1);
}
.visualizer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.device-bottom-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 60px;
  height: 66px;
  bottom: 15px;
  right: 15px;
  background: RGB(var(--vs-background));
  border-radius: 15px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
  z-index: 99;
  overflow: hidden;
}

.clip-item {
  position: relative;
  margin: 0 15px;
  background: RGB(var(--vs-background));
  border-radius: 15px;
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
  color: RGB(var(--vs-text));
  padding: 15px;
  margin-bottom: 15px;
  width: calc(100% - 60px);
  height: auto;
  min-height: 132px;

  .clip-audio {
    width: 100%;
    border-radius: 10px;
  }
  @supports (-moz-appearance: none) {
    .clip-audio {
      filter: invert(1);
    }
  }
  .clip-audio::-webkit-media-controls-enclosure {
    border-radius: 10px;
  }
  .clip-item-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;

    .clip-item-label {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      button {
        display: inline-flex;
        span {
          font-size: 18px;
        }
      }
    }
  }
}
.actions-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.editclip {
  .vs-input {
    width: 100% !important;
  }
}
.editinputdevice {
  .vs-select-content {
    max-width: none;
  }
}
.clip-infos {
  display: flex;
  flex-wrap: wrap;
  font-size: 90%;
  color: RGB(var(--vs-text), 0.6);
  cursor: default;

  .clip-info-icon {
    vertical-align: bottom;
    font-size: 120%;
    margin: 0 5px 0 10px;
    opacity: 0.5;
  }
  .clip-upload-icon {
    vertical-align: middle;
    font-size: 150%;
    margin: 0 5px 0 10px;
    color: rgba(var(--vs-success), 1);
  }
}

body[vs-theme="dark"] {
  .clip-audio {
    filter: invert(1);
  }
  @supports (-moz-appearance: none) {
    .clip-audio {
      filter: invert(0);
    }
  }
}

.disabled-list {
  opacity: 0;
}
.list {
  opacity: 1;
}

.list-move,
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter,
.list-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(60px, -100%);
}
.list-leave-active {
  position: absolute;
}
</style>

<script>
import * as dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/de";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(customParseFormat);
dayjs.extend(relativeTime);
dayjs.locale("de");

import Upload from "../modules/Upload.vue";
import { mapState } from "pinia";
import { mapActions } from "pinia";
import { useClipsStore } from "@/stores/clips";
import { useUserStore } from "@/stores/user";

export default {
  name: "Record",
  components: {
    Upload,
  },
  data: () => ({
    recorder: null,
    stream: null,
    mimeType: "audio/webm",
    recorderOptions: {
      mimeType: "audio/webm",
      audioBitsPerSecond: 128000,
    },
    audiosrc: null,
    recordInterval: null,
    recordTime: 0,
    mediaRecorder: null,
    recorderRecording: false,
    audioCtx: null,
    canvas: null,
    canvasCtx: null,
    visualizerInterval: null,
    uploadModal: false,
    clipRenameModal: false,
    clipToUpload: null,
    editclip: {
      id: null,
      name: "",
      audio: null,
    },
    inputDeviceModal: false,
    inputDevices: [],
    selectedInputDevice: "",
    transitiongroupname: "disabled-list",
  }),
  methods: {
    ...mapActions(useClipsStore, ["getClips", "deleteClip", "saveClip"]),
    record() {
      if (this.mediaRecorder) {
        this.recordTime = 0;
        this.mediaRecorder.start();
        //console.log(this.mediaRecorder.state);
        this.recorderRecording = true;
        //console.log("recorder started");
        this.recordInterval = setInterval(() => {
          this.recordTime++;
          if (this.recordTime > 180) {
            this.stop();
          }
        }, 1000);
      } else {
        alert("Recorder ist nicht verfügbar!");
      }
    },
    stop() {
      if (this.mediaRecorder) {
        this.mediaRecorder.stop();
        //console.log(this.mediaRecorder.state);
        this.recorderRecording = false;
        //console.log("recorder stopped");
        clearInterval(this.recordInterval);
        //this.recordTime = 0;
      } else {
        alert("Recorder ist nicht verfügbar!");
      }
    },
    visualize(stream) {
      if (!this.audioCtx) {
        this.audioCtx = new AudioContext();
      }

      const source = this.audioCtx.createMediaStreamSource(stream);

      const analyser = this.audioCtx.createAnalyser();
      analyser.fftSize = 2048;
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      source.connect(analyser);
      //analyser.connect(audioCtx.destination);

      let draw = () => {
        const WIDTH = this.canvas.width;
        const HEIGHT = this.canvas.height;

        this.visualizerInterval = requestAnimationFrame(draw);

        analyser.getByteTimeDomainData(dataArray);

        //this.canvasCtx.fillStyle = "rgb(200, 200, 200)";
        //this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);
        this.canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        this.canvasCtx.lineWidth = 2;

        if (localStorage.vsTheme && localStorage.vsTheme == "dark") {
          this.canvasCtx.strokeStyle = "rgba(255, 255, 255, 0.3)";
        } else {
          this.canvasCtx.strokeStyle = "rgba(0, 0, 0, 0.2)";
        }

        this.canvasCtx.beginPath();

        let sliceWidth = (WIDTH * 1.0) / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {
          let v = dataArray[i] / 128.0;
          let y = (v * HEIGHT) / 2;

          if (i === 0) {
            this.canvasCtx.moveTo(x, y);
          } else {
            this.canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
        }

        this.canvasCtx.lineTo(this.canvas.width, this.canvas.height / 2);
        this.canvasCtx.stroke();
      };

      draw();
    },
    uploadClip(clip) {
      this.uploadModal = true;
      this.clipToUpload = clip;
    },
    scrollToStart(instant = false) {
      setTimeout(
        () => {
          window.scrollTo({
            left: 0,
            top: 0 /*document.body.scrollHeight*/,
            behavior: instant ? "auto" : "smooth",
          });
        },
        instant ? 0 : 200
      );
    },
    editClip(clip) {
      this.editclip = JSON.parse(JSON.stringify(clip));
      this.clipRenameModal = true;
    },
    renameClip(clip) {
      if (!clip.name) {
        clip.name = clip.id;
      }
      this.saveClip(clip).then(() => {
        this.clips.find((x) => x.id == clip.id).name = clip.name;
        this.clipRenameModal = false;
        /*
        this.getClips().then(() => {
          this.scrollToStart(true);
          this.clipRenameModal = false;
        });
        */
      });
    },
    deleteClipModal(clip) {
      this.$set(clip, "hidden", true);
      const undoTimeout = setTimeout(() => {
        this.deleteClip(clip).then(() => {
          /*
          this.getClips().then(() => {
            this.scrollToStart(true);
          });
          */
        });
      }, 3000);

      const undoNoti = this.$vs.notification({
        icon: "<span class='material-icons-outlined'>delete</span>",
        color: "danger",
        position: "bottom-right",
        duration: 3000,
        progress: "auto",
        title: "Clip wird gelöscht...",
        buttonClose: false,
        text:
          clip.name +
          `<br><button class="vs-button vs-button--null vs-button--size-null vs-button--fff vs-button--flat" style="--vs-color:255,255,255;"><div class="vs-button__content"> Abbrechen </div></button>`,
        onClick: () => {
          this.$set(clip, "hidden", false);
          clearTimeout(undoTimeout);
          undoNoti.close();
          //this.scrollToStart(true);
        },
      });
    },
    clipdate(time) {
      let timestamp = dayjs(time, "YYYY-MM-DD-HH-mm-ss");
      return [timestamp.fromNow(), timestamp.format("D. MMMM YYYY - HH:mm:ss")];
    },
    loadRecorder() {
      if (!navigator.mediaDevices.getUserMedia) {
        console.log("getUserMedia not supported on your browser!");
      } else {
        this.canvas = this.$refs.visualizer;
        this.canvasCtx = this.canvas.getContext("2d");

        //const constraints = { audio: true, video: false };
        let audiodevice = true;
        if (this.selectedInputDevice) {
          audiodevice = {
            deviceId: {
              exact: this.selectedInputDevice,
            },
          };
        }
        const constraints = {
          audio: audiodevice,
          video: false,
        };
        let chunks = [];

        let onSuccess = (stream) => {
          try {
            const types = [
              "audio/webm;codecs=opus",
              "audio/webm",
              "audio/mpeg",
              "audio/mpeg;codecs=mp3",
              "audio/mp3",
              "audio/mp4",
              "audio/mp4;codecs=aac",
              "audio/aac",
              "audio/x-m4a",
              "audio/mpeg",
              "audio/opus",
              "audio/ogg",
              "audio/wav",
              "audio/3gpp",
              "audio/3gpp2",
            ];
            console.groupCollapsed("Type Support");
            for (let i in types) {
              console.log(
                types[i],
                MediaRecorder.isTypeSupported(types[i]) ? "YES" : "NO"
              );
            }
            console.groupEnd("Type Support");
            let canSupportMime = false;
            if (!MediaRecorder.isTypeSupported) {
              // Apple iPhone
              this.recorderOptions.mimeType = "audio/mp4";
              canSupportMime = true;
              console.log(
                "Recording mit " +
                  this.recorderOptions.mimeType +
                  ", " +
                  this.recorderOptions.audioBitsPerSecond +
                  " (iPhone)"
              );
            } else {
              for (let i in types) {
                if (MediaRecorder.isTypeSupported(types[i])) {
                  this.recorderOptions.mimeType = types[i];
                  canSupportMime = true;
                  break;
                }
              }
              if (canSupportMime) {
                console.log(
                  "Recording mit " +
                    this.recorderOptions.mimeType +
                    ", " +
                    this.recorderOptions.audioBitsPerSecond
                );
              } else {
                console.log(
                  "Browser unterstützt kein MediaRecorder.isTypeSupported / alle Mime. Recording mit default options"
                );
              }
            }
            if (canSupportMime) {
              this.mediaRecorder = new MediaRecorder(
                stream,
                this.recorderOptions
              );
            } else {
              this.mediaRecorder = new MediaRecorder(stream);
            }

            this.visualize(stream);

            this.mediaRecorder.ondataavailable = (e) => {
              chunks.push(e.data);

              let reader = new FileReader();
              reader.onloadend = () => {
                //console.log(reader.result);
                let filename = dayjs().format("YYYY-MM-DD-HH-mm-ss");
                let clip = {
                  id: filename,
                  name: filename,
                  audio: reader.result,
                  duration: this.recordTime,
                };
                this.recordTime = 0;
                //this.clips.push(clip);

                this.saveClip(clip).then(() => {
                  this.clips.unshift(clip);
                  /*
                  this.getClips().then(() => {
                    //console.log("recorder stopped");
                    this.scrollToStart();
                  });
                  */
                });
              };
              reader.readAsDataURL(e.data);
            };
          } catch (e) {
            console.log(e);
          }
        };

        let onError = (err) => {
          console.log("navigator.mediaDevices.getUserMedia: " + err);
          alert("Dein Browser unterstützt diese App nicht");
        };

        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          navigator.mediaDevices
            .getUserMedia(constraints)
            .then(onSuccess, onError);
        } else {
          console.log("navigator.mediaDevices.getUserMedia nicht verfügbar");
          alert("Dein Browser unterstützt diese App nicht");
        }
      }
    },
    timer(time, formatted = false) {
      var minutes = Math.floor(time / 60);
      var seconds = time - minutes * 60;
      if (formatted) {
        return (
          (minutes ? minutes.toString() + "m " : "") + seconds.toString() + "s"
        );
      }
      return [minutes.toString(), seconds.toString().padStart(2, "0")];
    },
  },
  computed: {
    ...mapState(useClipsStore, ["clips"]),
    ...mapState(useUserStore, ["user"]),
    filteredClips() {
      return this.clips.filter((x) => !x.hidden); //.reverse();
    },
  },
  mounted() {
    if (!this.clips.length) {
      this.getClips().then(() => {
        this.scrollToStart();
        setTimeout(() => {
          this.transitiongroupname = "list";
        }, 1000);
      });
    }
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then(() => {
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            console.groupCollapsed("Input Devices");
            devices.forEach((device) => {
              if (device.kind == "audioinput") {
                console.log(device);

                this.inputDevices.push({
                  deviceId: device.deviceId,
                  label: device.label || "Standard",
                });
              }
            });
            console.groupEnd("Input Devices");
          });
        });
    }
    this.loadRecorder();
  },
  watch: {
    "user.rm_user_id": function() {
      if (!this.clips.length) {
        this.getClips().then(() => {
          this.scrollToStart();
          setTimeout(() => {
            this.transitiongroupname = "list";
          }, 1000);
        });
      }
    },
    selectedInputDevice: function() {
      this.loadRecorder();
    },
  },
};
</script>
