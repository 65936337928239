import Vue from "vue";
import Vuesax from "vuesax";
import VueRouter from "vue-router";
import App from "./App.vue";

import axios from "axios";
import { createPinia, PiniaVuePlugin } from "pinia";
import { authOptions } from "./store";

import { useUserStore } from "@/stores/user";

import "vuesax/dist/vuesax.css";
import "./styles/main.scss";

// PHP Session übergeben
//axios.defaults.withCredentials = true;

Vue.use(VueRouter);
Vue.use(PiniaVuePlugin);
Vue.use(Vuesax, {
  colors: {
    primary: "#536dfe",
    success: "#32db64",
    danger: "#f44336",
    warning: "#FFBA00",
    dark: "#222",
  },
});

const pinia = createPinia();

Vue.config.productionTip = false;
Vue.config.devtools = process.env.NODE_ENV == "development";

(async () => {
  // Keycloak Refresh Token holen & checken
  await axios
    .get(authOptions.refresh, { withCredentials: true })
    .then(({ data }) => {
      //console.log(data);
      if (!data.expires) {
        location.href = authOptions.login;
      }
    })
    .catch((e) => {
      console.log(e);
      location.href = authOptions.login;
    });

  const routes = [
    {
      path: "/record",
      name: "Record",
      component: require("./components/pages/Record.vue").default,
    },
    {
      path: "/play",
      name: "Play",
      component: require("./components/pages/Play.vue").default,
    },
    {
      path: "/settings",
      name: "Settings",
      component: require("./components/pages/Settings.vue").default,
    },
    {
      path: "/login",
      name: "Login",
      component: require("./components/pages/Login.vue").default,
    },
    { path: "*", redirect: "/record" },
  ];

  const router = new VueRouter({
    routes, // short for `routes: routes`
  });

  new Vue({
    router,
    pinia,
    render: (h) => h(App),
  }).$mount("#app");

  router.beforeEach((to, from, next) => {
    const userStore = useUserStore();
    if (userStore.auth) {
      next();
    } else {
      if (to.name != "Login") {
        next("/login");
      } else {
        next();
      }
    }
  });

  // Jede Minute Refresh Token aktualisieren
  setInterval(() => {
    axios
      .get(authOptions.refresh, { withCredentials: true })
      .then(({ data }) => {
        //console.log(data);
        if (!data.expires) {
          location.href = authOptions.login;
        }
      })
      .catch((e) => {
        console.log(e);
        location.href = authOptions.login;
      });
  }, 60000);
})();
