import axios from "axios";

const base_url =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000/"
    : "https://2go.rm.fm/";

export const authOptions = {
  login: base_url + "login.php",
  logout: base_url + "logout.php",
  refresh: base_url + "refresh.php",
  user: base_url + "user.php",
  auth: base_url + "auth.php?id=",
  streams: base_url + "api/stream_api.php",
  proxy: base_url + "api/proxy.php?rmms",
  socket: "https://ws-api.rautemusik.fm",
};

export function getUser() {
  return new Promise((resolve, reject) => {
    axios
      .get(authOptions.user, { withCredentials: true })
      .then(({ data }) => {
        resolve(data);
      })
      .catch((e) => {
        reject(e);
      });
  });
}
