<template>
  <div>
    <vs-row justify="center">
      <vs-col type="flex" justify="center" sm="12" lg="6">
        <vs-card type="1">
          <template #title>
            <vs-select
              filter
              placeholder="Sender"
              v-model="sender"
              danger
              @input="selectedStreamChange()"
            >
              <vs-option
                v-for="stream in sortedStreams"
                :label="stream.name"
                :value="stream.id"
                :key="stream.id"
              >
                {{ stream.name }}
              </vs-option>
            </vs-select>
          </template>
          <template #img>
            <img :src="streamImage" width="200" />
          </template>
          <template #text>
            <div class="grid play-stream-card p-top-15">
              <audio
                :src="streamAudio"
                controls
                id="play-stream-audio"
                class="clip-audio"
              ></audio>
            </div>
            <div class="track-box p-top-15">
              <div class="track-img">
                <img :src="track.track.picture.preview_url" />
              </div>
              <div class="track-infos">
                <div class="track-title">{{ track.track.name }}</div>
                <div class="track-artist">{{ track.artist.name }}</div>
              </div>
            </div>
          </template>
        </vs-card>
      </vs-col>
    </vs-row>

    <vs-row justify="center">
      <vs-col type="flex" justify="center" sm="12" lg="6">
        <div class="feed-wrapper">
          <div
            v-for="(msg, i) in chat.slice().reverse()"
            class="feed-item"
            :key="i"
          >
            <div class="feed-item-content">
              <vs-avatar :history="msg.teamMember" :danger="msg.teamMember">
                <img :src="msg.img" class="feed-img" />
              </vs-avatar>
              <div class="feed-bubble">
                <div class="feed-heading">
                  <p class="feed-user">{{ msg.user }}</p>
                  <p class="feed-date">{{ msg.date }}</p>
                </div>
                <p class="feed-message" v-html="msg.msg"></p>
              </div>
            </div>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>


<style scoped lang="scss">
.play-stream-card {
  /*min-width: 350px;*/
}
.vs-select-content {
  max-width: 100%;
}
#play-stream-audio {
  width: 100%;
}
.track-box {
  display: flex;
  align-items: center;
  .track-img {
    margin-right: 15px;
    img {
      width: 70px;
      border-radius: 12px;
    }
  }
  .track-infos {
    .track-title {
      font-weight: bold;
      margin-bottom: 4px;
    }
    .track-artist {
      opacity: 0.7;
    }
  }
}
.feed-wrapper {
  margin-top: 20px;
  margin-bottom: -15px;
  background-color: transparent;
  width: 350px;
  max-width: 100%;

  .feed-item {
    display: flex;
    flex-direction: row;
    background-color: transparent;
    padding: 0;

    .feed-item-content {
      display: inline-flex;
      flex-direction: row;
      margin-bottom: 12px;

      .feed-img {
        box-sizing: border-box;
        object-fit: cover;
        width: 44px;
        height: 44px;
        min-width: 44px;
        vertical-align: middle;
      }

      .feed-bubble {
        position: relative;
        border-radius: 4px;
        position: relative;
        max-width: calc(300px + 25%);
        padding: 7px 10px;
        margin: 0 15px;
        background-color: RGB(var(--vs-background));

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: 16px;
          left: -8px;
          border-left: none;
          border-right: 8px solid RGB(var(--vs-background));
          border-bottom: 6px solid transparent;
          border-top: 6px solid transparent;
        }

        .feed-heading {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin: 0.1em 0 0.3em;

          .feed-user {
            font-weight: 600;
            font-size: 14px;
            opacity: 1;
            line-height: normal;
            margin: 0 !important;
            color: RGB(var(--vs-text));
          }
          .feed-date {
            font-size: 11px;
            color: RGBA(var(--vs-text), 0.5);
            margin: 0 0 0 10px !important;
          }
        }

        .feed-message {
          font-size: 14px;
          margin: 0 0 0.1em 0 !important;
          color: RGBA(var(--vs-text), 0.7);
          line-height: 1.2em;
          word-wrap: break-word;
        }
      }
    }
  }
}
</style>

<style>
.feed-wrapper .feed-message .chatlink {
  color: RGBA(var(--vs-text), 0.7);
  text-decoration: underline;
}
</style>

<script>
import { mapActions, mapState } from "pinia";
import { useStreamsStore } from "@/stores/streams";
import { useSettingsStore } from "@/stores/settings";
import { useUserStore } from "@/stores/user";
import { useSocketStore } from "@/stores/socket";

export default {
  name: "Play",
  data: () => ({
    sender: "club",
  }),
  computed: {
    ...mapState(useStreamsStore, ["streams"]),
    ...mapState(useSettingsStore, ["settings"]),
    ...mapState(useUserStore, ["user"]),
    ...mapState(useSocketStore, ["socketio", "track", "chat"]),
    sortedStreams() {
      if (this.streams) {
        if (this.user.auth.streams && this.user.auth.streams[0] == "all") {
          return this.streams;
        } else {
          if (this.user.auth.streams) {
            if (this.settings.playListOnlyAvailable) {
              return this.streams.filter((x) =>
                this.user.auth.streams.includes(x.id)
              );
            } else {
              return this.streams;
            }
          } else {
            return this.streams;
          }
        }
      } else {
        return [];
      }
    },
    streamImage() {
      let curSender = this.streams.find((x) => x.id == this.sender);
      return curSender
        ? curSender.picture.url
        : "https://static.rautemusik.fm/assets/streamlogos/club_square_60560c4b76568.jpg";
    },
    streamAudio() {
      let curSender = this.streams.find((x) => x.id == this.sender);
      let tuneinurl =
        (curSender
          ? curSender.tunein_urls.aacp
          : "http://club-aacp.rautemusik.fm") + "?context=fHA6LTE=";
      return tuneinurl;
    },
  },
  methods: {
    ...mapActions(useSettingsStore, ["setLastPlayedSender"]),
    ...mapActions(useSocketStore, ["subscribeStream"]),
    setDefaultSender() {
      this.sender = this.settings.lastPlayedSender;
      this.subscribeStream(this.sender);
    },
    selectedStreamChange() {
      this.setLastPlayedSender(this.sender);
      this.subscribeStream(this.sender);
    },
  },
  watch: {
    "settings.lastPlayedSender": function () {
      this.setDefaultSender();
    },
    socketio: function () {
      this.subscribeStream(this.sender);
    },
  },
  created() {
    this.setDefaultSender();
  },
};
</script>