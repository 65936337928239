import { defineStore } from "pinia";
import * as io from "socket.io-client";
import { authOptions } from "../store";

export const useSocketStore = defineStore("socket", {
  // convert to a function
  state: () => ({
    socket: null,
    track: {
      track: {
        id: "0",
        name: "Unknown",
        slug: "Unknown",
        published: true,
        picture: {
          preview_url: require("@/assets/myRM-transparent.png"),
        },
      },
      artist: {
        id: "0",
        name: "Unknown",
        slug: "Unknown",
        published: true,
      },
    },
    chat: [],
  }),
  getters: {
    socketio(state) {
      return state.socket;
    },
  },
  actions: {
    initializeSocket() {
      this.socket = io.connect(authOptions.socket);

      this.socket.on("connect", () => {
        //console.log("on connect", msg);
      });

      this.socket.on("event", (eventObj) => {
        //console.log(eventObj);

        if (eventObj.type == "track") {
          this.track = eventObj.data;
        } else if (eventObj.type == "chat") {
          let msg = eventObj;
          msg = this.formatMessage(msg);

          // Ältere löschen
          if (this.chat.length > 30) {
            this.chat.shift();
          }
          this.chat.push(msg);
        }
      });
    },
    subscribeStream(name) {
      if (this.socket) {
        //console.log("subscribe", name);
        this.chat = [];
        this.socket.emit("subscribe", {
          stream: name,
          events: ["chat", "track"],
        });
      }
    },
    padNumber(num) {
      return (num < 10 ? "0" : "") + num;
    },
    escapeHtml(unsafe) {
      return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
    },
    linkify(inputText) {
      var replacedText, replacePattern1, replacePattern2;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
      replacedText = inputText.replace(
        replacePattern1,
        '<a href="$1" target="_blank" rel="nofollow" class="chatlink">$1</a>'
      );

      //URLs starting with "www."
      replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank" rel="nofollow" class="chatlink">$2</a>'
      );

      return replacedText;
    },
    formatMessage(message) {
      if (message) {
        let datetime = new Date(message.time);
        let dtime =
          this.padNumber(datetime.getDate()) +
          "." +
          this.padNumber(datetime.getMonth() + 1) +
          ". " +
          this.padNumber(datetime.getHours()) +
          ":" +
          this.padNumber(datetime.getMinutes());

        let chatMessage = {
          img: message.data.profile.picture.thumb_url,
          userId: message.data.profile.id,
          teamMember: message.data.profile.team_member,
          user: this.escapeHtml(message.data.sender),
          date: dtime,
          msg: this.linkify(this.escapeHtml(message.data.message)),
        };
        return chatMessage;
      }
      return null;
    },
  },
});
