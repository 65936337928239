import { defineStore } from "pinia";
import { localForageService } from "./localForageService";
import { useUserStore } from "./user";

export const useClipsStore = defineStore("clips", {
  state: () => ({
    cliplist: [],
  }),
  getters: {
    clips(state) {
      return state.cliplist;
    },
  },
  actions: {
    async dataURLtoFile(dataurl) {
      const arr = dataurl.split(",");
      const mime = arr[0].match(/:(.*?);/)[1];

      const res = await fetch(dataurl);
      const buf = await res.arrayBuffer();
      return new File([buf], "clip", { type: mime });
    },
    deleteClip(clip) {
      return new Promise((resolve, reject) => {
        localForageService
          .removeItem(clip.id)
          .then(() => {
            //console.log("Deleted", clip.id);
            resolve(clip);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    getClips() {
      return new Promise((resolve, reject) => {
        this.cliplist = [];
        const userStore = useUserStore();
        localForageService
          .iterate((value) => {
            if (userStore.rm_user_id == value.user) {
              this.cliplist.unshift(value);
            }
            //console.log([key, value]);
          })
          .then(() => {
            //console.log("Iteration has completed");
            resolve(this.cliplist);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    saveClip(clip) {
      delete clip.hidden;
      return new Promise((resolve, reject) => {
        const userStore = useUserStore();
        clip = { user: userStore.rm_user_id, ...clip };
        localForageService
          .setItem(clip.id, clip)
          .then(() => {
            resolve(clip);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
  },
});
