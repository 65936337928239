<template>
  <vs-row justify="center" align="center">
    <vs-col
      type="flex"
      justify="center"
      align="center"
      xs="12"
      lg="6"
      class="clip-item-wrapper"
    >
      <vs-alert danger relief>
        <template #icon>
          <span class="material-icons-outlined">lock</span>
        </template>
        <template #title> Willkommen bei RM2GO! </template>
        <div class="text-left">
          Für diese App benötigst du einen Account bei RauteMusik.FM sowie eine
          Freischaltung vom RM.FM Technik-Team.<br />
          Bitte gebe die ID
          <b>{{ user && user.rm_user_id ? user.rm_user_id : "xxxxxx" }}</b> an.
        </div>
      </vs-alert>
    </vs-col>
  </vs-row>
</template>

<script>
import { mapState } from "pinia";
import { useUserStore } from "@/stores/user";

export default {
  name: "Login",
  data: () => ({}),
  computed: {
    ...mapState(useUserStore, ["user"]),
  },
};
</script>