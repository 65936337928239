<template>
  <div>
    <vs-row justify="center">
      <vs-col type="flex" justify="center" sm="12" lg="6">
        <vs-card type="3">
          <template #title>
            <h3>{{ user.preferred_username }}</h3>
          </template>
          <template #img>
            <img :src="user.picture" width="200" />
          </template>
          <template #text>
            <div class="grid user-info-card p-top-15">
              <vs-row class="m-bottom-5">
                <vs-col vs-type="flex" w="6">
                  <strong>Name</strong>
                </vs-col>
                <vs-col vs-type="flex" w="6">
                  {{ user.name }}
                </vs-col>
              </vs-row>
              <vs-row class="m-bottom-5">
                <vs-col vs-type="flex" w="6">
                  <strong>ID</strong>
                </vs-col>
                <vs-col vs-type="flex" w="6">
                  {{ user.rm_user_id }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-type="flex" w="6">
                  <strong>Streams</strong>
                </vs-col>
                <vs-col vs-type="flex" w="6" v-if="user.auth">
                  <div v-for="stream in user.auth.streams" :key="stream">
                    {{ stream }}
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </template>
          <template #interactions>
            <vs-button :danger="!user.auth" :success="!!user.auth" flat>
              <span class="span">
                {{ user.auth ? "Authorized" : "Unauthorized" }}
              </span>
            </vs-button>
          </template>
        </vs-card>
      </vs-col>
    </vs-row>

    <vs-row justify="center" class="m-top-30">
      <vs-col type="flex" justify="center" sm="12" lg="6">
        <div class="settings-box p-30">
          <vs-switch
            v-model="playListOnlyAvailable"
            danger
            class="m-right-15"
            @input="playListOnlyAvailableChange()"
          />
          <div class="text-white">
            Nur Streams zeigen, die für mich verfügbar sind
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>


<style scoped>
.user-info-card {
  min-width: 150px;
}
.settings-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: RGB(var(--vs-background));
  border-radius: var(--vs-radius);
  box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, var(--vs-shadow-opacity));
}
.text-white {
  color: RGB(var(--vs-text));
}
</style>

<script>
import { mapState, mapActions } from "pinia";
import { useUserStore } from "@/stores/user";
import { useSettingsStore } from "@/stores/settings";

export default {
  name: "Settings",
  data: () => ({
    playListOnlyAvailable: false,
  }),
  computed: {
    ...mapState(useUserStore, ["user"]),
    ...mapState(useSettingsStore, ["settings"]),
  },
  methods: {
    ...mapActions(useSettingsStore, ["togglePlayListOnlyAvailable"]),
    playListOnlyAvailableChange() {
      this.togglePlayListOnlyAvailable(this.playListOnlyAvailable);
    },
  },
  created() {
    this.playListOnlyAvailable = this.settings.playListOnlyAvailable || false;
  },
  watch: {
    "settings.playListOnlyAvailable": function () {
      this.playListOnlyAvailable = this.settings.playListOnlyAvailable || false;
    },
  },
};
</script>