<template>
  <div id="app">
    <Framework />
  </div>
</template>

<script>
import Framework from "./components/Framework.vue";

export default {
  name: "App",
  components: {
    Framework,
  },
};
</script>

<style>
html * {
  font-family: "Roboto", Helvetica Neue, sans-serif;
}
body {
  background: #f4f4f4 url("./assets/pattern-dark.png") top left repeat fixed;
}
body[vs-theme="dark"] {
  background: #3b3c3e url("./assets/pattern-light.png") top left repeat fixed;
}
#app {
  font-family: "Roboto", Helvetica Neue, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  text-align: left;
}
</style>
