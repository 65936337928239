<template>
  <div class="framework">
    <vs-navbar text-white color="#f44336" v-model="active" class="navbar">
      <template #left>
        <img src="../assets/myRM-transparent.png" alt="" height="30" />
      </template>
      <vs-navbar-item :active="activeTab == 'Record'" id="record" to="/record">
        <span class="material-icons-outlined">mic</span>
      </vs-navbar-item>
      <vs-navbar-item :active="activeTab == 'Play'" id="play" to="/play">
        <span class="material-icons-outlined">play_circle</span>
      </vs-navbar-item>
      <vs-navbar-item
        :active="activeTab == 'Settings'"
        id="settings"
        to="/settings"
      >
        <span class="material-icons-outlined">settings</span>
      </vs-navbar-item>
      <template #right>
        <vs-button icon flat color="#fff" @click="toggleNight()">
          <span class="material-icons-outlined">nightlight</span>
        </vs-button>
        <vs-button icon flat color="#fff" @click="logoutModal = true">
          <span class="material-icons-outlined">logout</span>
        </vs-button>
      </template>
    </vs-navbar>
    <div class="container">
      <div class="grid">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <router-view :key="$route.name"></router-view>
          </keep-alive>
        </transition>
      </div>
    </div>

    <vs-dialog width="150px" v-model="logoutModal">
      <template #header>
        <h4 class="not-margin">Ausloggen</h4>
      </template>
      <template #footer>
        <div class="actions-footer">
          <vs-button @click="logout()" danger> Logout </vs-button>
          <vs-button @click="logoutModal = false" transparent>
            Abbrechen
          </vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>


<style scoped lang="scss">
.navbar {
  position: fixed;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>

<script>
import { mapActions } from "pinia";
import { useUserStore } from "@/stores/user";
import { useStreamsStore } from "@/stores/streams";
import { useSettingsStore } from "@/stores/settings";
import { useSocketStore } from "@/stores/socket";

export default {
  name: "MYRM",
  data: () => ({
    active: "Record",
    logoutModal: false,
  }),
  computed: {
    activeTab() {
      return this.$route.name;
    },
  },
  methods: {
    ...mapActions(useUserStore, ["getUser", "getUserAuth", "logoutUser"]),
    ...mapActions(useStreamsStore, ["getStreams"]),
    ...mapActions(useSettingsStore, ["getSettings"]),
    ...mapActions(useSocketStore, ["initializeSocket"]),
    toggleNight() {
      this.$vs.toggleTheme();
    },
    logout() {
      this.logoutUser();
    },
  },
  mounted() {
    this.$vs.setTheme();
    const loading = this.$vs.loading({
      color: "danger",
    });
    this.getUser().then(() => {
      this.getStreams().then(() => {
        this.getUserAuth().then((data) => {
          this.getSettings();
          loading.close();
          if (!data.user) {
            this.$router.replace("/login");
          }
        });
      });
    });
    this.initializeSocket();
  },
};
</script>